









































































































































































//@import url(); 引入公共css类
.tabhome{
  background-color: white;
  margin-top: 0px;
  height: 76px;
  width: 230px;  
  display: flex;justify-content: center;align-items: center;
  font-size: 20px;
  cursor: pointer;
}
.tabhome.active {
  background: url('../assets/images/bossfront/Group 2148.png') no-repeat;
  background-size: 100%;
}
.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

img {
  height: 70px;
}

.el-row {
  margin-bottom: 20px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossfront/Group 2176.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}

.counseling {
z-index: 10;
right: 28px;
position: relative;
position: fixed;
width: 56px;
height: 344px;
bottom: 40px;
background: rgba(255, 255, 255, 0.8);
box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
border-radius: 12px 12px 12px 12px;
border: 1px solid #DCE0E4;
}
